"use client";

import { Bar, BarChart, CartesianGrid, XAxis, Cell, LabelList } from "recharts";

import { ChartConfig, ChartContainer } from "src/components/ui/chart";
import { ChartTooltip, ChartTooltipContent } from "src/components/ui/chart";
import { ChartLegend, ChartLegendContent } from "src/components/ui/chart";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Tooltip } from "../ui/tooltip";
import Decimal from "decimal.js";
("use client");

const chartConfig = {
  llm: {
    label: "Visitors",
  },
} satisfies ChartConfig;
export function HowItWorksChart(props: {
  data: {
    Llm: string;
    min: number;
    max: number;
    minPersona: string;
    maxPersona: string;
  }[];
}) {
  const { data } = props;
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-center">
          Most Preferred and Least Preferred Personas Per LLM
        </CardTitle>
        {/* <CardDescription>January - June 2024</CardDescription> */}
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis dataKey="Llm" />
            <ChartTooltip
              formatter={(value, name, props) => {
                const { payload, dataKey } = props;
                let persona;

                if (dataKey === "min") {
                  persona = payload.minPersona ?? "unknown";
                } else if (dataKey === "max") {
                  persona = payload.maxPersona ?? "unknown";
                }

                return [
                  persona as string,
                  `${dataKey === "max" ? "Most Preferred " : "Least Preferred "} Persona`,
                ];
              }}
            />
            <Bar dataKey="max" fill="hsl(var(--chart-2))" name="Max">
              <LabelList position="top" fillOpacity={1} />
            </Bar>
            <Bar dataKey="min" fill="hsl(var(--chart-1))" name="Min">
              <LabelList position="top" fillOpacity={1} />
            </Bar>
            {/* Bar for 'max' values */}
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="flex gap-2 font-medium leading-none">
          {`*Results represent the distance from the median, which is calcualted by taking the median score for
        a persona for an llm, minus that llm's median score across all
        personas. The above graph displays the personas that have the greatest distance from the median for each llm. `}{" "}
        </div>
        {/* <div className="leading-none text-muted-foreground">
          Showing total visitors for the last 6 months
        </div> */}
      </CardFooter>
    </Card>
  );
}
