import { useQuery } from "@tanstack/react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { fetchExperimentById } from "src/api/experimentAPI";
import { Experiment } from "src/types/models/Experiment";
import { DataTable } from "./howItWorksDataTable/data-table";
import { columns } from "./howItWorksDataTable/characteristic-columns";

const HowItWorksTable = (props: { data: Experiment | null | undefined }) => {
  const { data } = props;

  return (
    <div>
      <h3 className="text-lg text-center font-bold">
        Median Characteristic Score for each LLM
      </h3>
      <span>
        {`*The variation from median is calcualted by taking the median score for
        a characteristic for an llm, minus that llm's median across all
        characteristics.  Positive means more preferred by the llm; negative means less preferred. A score of 0 means that no bias is detected.
        `}
      </span>
      <DataTable columns={columns} data={data?.characteristicResults ?? []} />

      {/* <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Characteristic</TableHead>
            <TableHead>llm</TableHead>
            <TableHead>Variation from Median:</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.characteristicResults &&
            data.characteristicResults?.map((characteristicResult) => {
              const llmMedianResult = data?.llmModelResults?.find(
                (model) => model?.llmModelId == characteristicResult.llmModelId,
              );
              let distanceFromMedian: string | number = "N/A";
              if (llmMedianResult) {
                distanceFromMedian =
                  characteristicResult.scoreMedian -
                  llmMedianResult.scoreMedian;
              }
              return (
                <TableRow
                  key={
                    characteristicResult.characteristicId +
                    characteristicResult.llmModelId
                  }
                >
                  <TableCell>
                    {characteristicResult.characteristic?.name}
                  </TableCell>
                  <TableCell>
                    {characteristicResult.llmModel?.friendlyName}
                  </TableCell>
                  <TableCell>{distanceFromMedian}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table> */}
    </div>
  );
};
export default HowItWorksTable;
