import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
// import SearchBar from "../SearchBar";
import { Navbar } from "flowbite-react";
import { SignOutButton, useAuth, useUser } from "@clerk/clerk-react";
// import conversationSearchButton from "../logos/messages-2.svg";
// import groupOfPeopleIcon from "../logos/people.svg";
interface LayoutProps {
  children: React.ReactNode;
  pageName: string;
  aside?: () => JSX.Element;
}

const HomePageLayout: React.FC<LayoutProps> = ({
  children,
  aside,
  pageName,
}) => {
  const { isSignedIn } = useUser();

  return (
    <div className="min-h-screen flex flex-col ">
      <div className="page-layout-horizontal-padding bg-henna-background">
        <Navbar fluid rounded className="bg-henna-background">
          <Navbar.Brand as={Link} to="/">
            <img
              src="./henna.png"
              className="h-6 sm:h-9 relative  right-4 sm:right-6 md:right-8"
              alt="Henna"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="">
            {pageName == "Home" && (
              <Navbar.Link href="/" className="mt-2 underline">
                Home
              </Navbar.Link>
            )}
            {pageName == "Home" && (
              <Navbar.Link href="/#problem" className="mt-2">
                Problem
              </Navbar.Link>
            )}
            {pageName == "Home" && (
              <Navbar.Link href="/#solution" className="mt-2">
                Solution
              </Navbar.Link>
            )}
            {pageName == "Home" && (
              <Navbar.Link href="/#press" className="mt-2">
                Press
              </Navbar.Link>
            )}
            {pageName == "Home" && (
              <Link
                to="https://calendly.com/arshhaque/call"
                className="btn-henna"
              >
                Schedule a Call
              </Link>
            )}

            {pageName == "How It Works" && (
              <Navbar.Link href="/how-it-works#research" className="mt-2">
                Research
              </Navbar.Link>
            )}
            {pageName == "How It Works" && (
              <Navbar.Link href="/how-it-works#inputs" className="mt-2">
                Inputs
              </Navbar.Link>
            )}
            {pageName == "How It Works" && (
              <Navbar.Link href="/how-it-works#process" className="mt-2">
                Process
              </Navbar.Link>
            )}
            {pageName == "How It Works" && (
              <Navbar.Link href="/how-it-works#outputs" className="mt-2">
                Outputs
              </Navbar.Link>
            )}
          </Navbar.Collapse>
        </Navbar>
      </div>

      <main className="flex flex-col flex-grow">{children}</main>
      <footer className="bg-henna-gray-2 grid grid-cols-1 md:grid-cols-3 page-layout-horizontal-padding py-24 gap-6">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <Link to="/" className="flex justify-center sm:justify-start">
              <img src="/henna.png" width="150px" />
            </Link>
            <span className="text-almost-black text text-center sm:text-start">
              The Auditing Platform for AI Bias
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="font-bold">Contact Us</span>
            <span className="text-almost-black">info@henna.com</span>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <span className="font-bold text-lg">Quick Links</span>
            <img src="./divider-start.png" alt="divider" />
          </div>

          <a href="/" className="text-almost-black">
            Home
          </a>
          <a href="/#problem" className="text-almost-black">
            Problem
          </a>
          <a href="/#solution" className="text-almost-black">
            Solution
          </a>
          <a href="/#press" className="text-almost-black">
            Press
          </a>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <span className="font-bold text-lg">Legal</span>
            <img src="./divider-start.png" alt="divider" />
          </div>

          <Link to="/terms" className="text-almost-black">
            Terms and conditions
          </Link>
          <Link to="/privacy-policy" className="text-almost-black">
            Privacy Policy
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default HomePageLayout;
