import { Paperclip } from "lucide-react";

const TextBubble = (props: {
  isLeft: boolean;
  username?: string;
  text: string;
  includeAttachment?: boolean;
}) => {
  const { text, isLeft, includeAttachment } = props;
  const leftBubbleClasses = "justify-start";
  const rightBubbleClasses = "justify-end";
  return (
    <div className={`flex ${isLeft ? leftBubbleClasses : rightBubbleClasses}`}>
      <div
        className={`p-2 border-2 rounded-2xl w-5/6 ${isLeft ? "bg-henna-background rounded-tl-none" : "bg-off-white rounded-br-none"}`}
      >
        <span>{text}</span>
        {includeAttachment && (
          <div className="flex justify-end">
            <Paperclip />
          </div>
        )}
      </div>
    </div>
  );
};
export default TextBubble;
