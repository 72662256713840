import React, { useState } from "react";
import { Link } from "react-router-dom";
import HomePageLayout from "../components/layout/HomePageLayout";
import TaskCard from "../components/TaskCard";
import PressCard from "../components/PressCard";
import HowItWorksStep from "../components/howItWorks/HowItWorksStep";
import EnterPasswordForm from "../components/user/EnterPasswordForm";
import FullPageSection from "../components/layout/FullPageSection";
import HowItWorksLogin from "src/components/howItWorks/HowItWorksLogin";
import HowItWorksMainInfo from "src/components/howItWorks/HowItWorksMainInfo";
const HowItWorksPage: React.FC = () => {
  const [knowsPassword, setKnowsPassword] = useState(false);
  return (
    <HomePageLayout pageName="How It Works">
      <div className="flex flex-col bg-off-white">
        {!knowsPassword && (
          <HowItWorksLogin setKnowsPassword={setKnowsPassword} />
        )}
        {knowsPassword && <HowItWorksMainInfo></HowItWorksMainInfo>}
      </div>
    </HomePageLayout>
  );
};

export default HowItWorksPage;
