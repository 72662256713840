import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { verifyPassword } from "../../api/userAPI";
import ButtonOutline from "../ButtonOutline";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
const EnterPasswordForm: React.FC<{
  setKnowsPassword: (val: boolean) => void;
}> = ({ setKnowsPassword }) => {
  const [password, setPassword] = useState("");
  const mutation = useMutation({
    mutationFn: verifyPassword,
    onSuccess: (data: boolean) => {
      if (data === true) {
        setKnowsPassword(true);
      }
    },
    onError: (error: Error) => {
      console.error(
        error?.message ?? "There was an error entering your password",
      );
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate(password);
  };

  const LoginMessage = () => (
    <div
      className="mb-8 flex flex-col justify-center items-center"
      style={{ width: "314.8px", fontFamily: "Lexend" }}
    >
      <div className="flex justify-center mb-4">
        <img className="w-full" src="./app-development.png" alt="" />
      </div>
      <p className="text-lg">
        At the moment, access to this page is password-restricted. Please enter
        the correct password to continue on this page.
      </p>
    </div>
  );

  return (
    <div className="w-full p-10 flex flex-col justify-center items-center">
      <LoginMessage />
      <form onSubmit={handleSubmit} className="flex flex-col justify-center">
        <div>
          <Input
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>{" "}
        <div className="flex items-center mt-4 mb-4"></div>
        <Button>Submit</Button>
        <p className="mt-2">{mutation.isError && mutation?.error.message}</p>
      </form>
    </div>
  );
};

export default EnterPasswordForm;
