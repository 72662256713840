import React from "react";
import { useMutation } from "@tanstack/react-query";
import { verifyPassword } from "../../api/userAPI";
import ButtonOutline from "../ButtonOutline";
const EnterPasswordFormForLogin: React.FC<{
  password: string;
  setPassword: (password: string) => void;
  setKnowsPassword: (val: boolean) => void;
}> = ({ password, setPassword, setKnowsPassword }) => {
  const mutation = useMutation({
    mutationFn: verifyPassword,
    onSuccess: (data: boolean) => {
      if (data === true) {
        setKnowsPassword(true);
      }
    },
    onError: (error: Error) => {
      console.error(
        error?.message ?? "There was an error entering your password",
      );
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate(password);
  };

  const LoginMessage = () => (
    <div
      className="mb-8 flex flex-col justify-center items-center"
      style={{ width: "314.8px", fontFamily: "Lexend" }}
    >
      <div className="flex justify-center mb-4">
        <img
          src="./Henna-Background-mobile.png"
          alt="Henna's premier mobile logo"
        />
      </div>
      <div
        className="text-xl font-semibold leading-8 tracking-tight"
        style={{
          fontSize: "20px",
          fontWeight: "600",
          lineHeight: "27px",
          letterSpacing: "0em",
        }}
      >
        At the moment, access to Henna is limited to our pilot program users. If
        you are a member of a pilot program, please enter the pilot password to
        continue:
      </div>
    </div>
  );

  return (
    <div className="w-full p-10 flex flex-col justify-center items-center">
      <LoginMessage />
      <form onSubmit={handleSubmit} className="flex flex-col justify-center">
        <div>
          <input
            id="password"
            type="text"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="border-b border-gray-300 py-2 px-3 placeholder-gray-400 text-gray-700 focus:outline-none focus:ring focus:border-blue-500 mb-3"
          />
        </div>{" "}
        <div className="flex items-center mt-4 mb-4"></div>
        <ButtonOutline text="Submit" />
        <p className="mt-2">{mutation.isError && mutation?.error.message}</p>
      </form>
    </div>
  );
};

export default EnterPasswordFormForLogin;
