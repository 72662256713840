import { ReactNode } from "react";
// import { ReactComponent as NotificationBell } from "./logos/notificationBell.svg";

import { Card, CardContent, CardTitle, CardHeader } from "../ui/card";
import { CardFooter } from "react-bootstrap";
export interface PressCardProps {
  header: string;
  body: ReactNode;
  tags?: string[];
  externalLink?: string;
}
const CaseStudyCard = (props: PressCardProps) => {
  // Close dropdown if clicked outside
  const { header, body, tags } = props;

  return (
    <div className="h-full">
      <Card className="bg-off-white border border-henna-green-accent rounded-tl-xxxl rounded-br-xxxl h-full flex flex-col gap-4 overflow-clip">
        <CardHeader className="p-0">
          <div className="w-full h-20 bg-henna-dark-green text-white flex items-center justify-center">
            {" "}
            <span className="text-3xl ">Case Study</span>
          </div>
        </CardHeader>
        <CardContent className="flex flex-col gap-4">
          <div className="flex mx-2 my-2 gap-2">
            {tags?.map((tag, idx) => (
              <span className="tag" key={idx}>
                {tag}
              </span>
            ))}
          </div>
          <CardTitle className="header-henna-5 font-bold">{header}</CardTitle>

          {body}
        </CardContent>
      </Card>
    </div>
  );
};
// Figure out how to style the SignOutButton component later so we don't have to wrap it with a div for styling
export default CaseStudyCard;
