import axios, { AxiosError } from "axios";
import { Experiment } from "../types/models/Experiment";

// export interface ExperimentInfo {
//   token: string;
//   type: string;
//   data:
// }
const backendURL = process.env.REACT_APP_BACKEND_URL;

// export async function createExperiment(
//   experimentInfo: ExperimentInfo,
// ): Promise<Experiment> {
//   const response = await axios.post(
//     `${backendURL}/experiment/${experimentInfo}`,
//     { type: experimentInfo.type },
//     {
//       headers: {
//         "Content-Type": "application/json", // Example header
//         Authorization: `Bearer ${experimentInfo.token}`,
//       },
//     },
//   );
//   console.log("this is the response from api", response);
//   if (response?.data?.experiment) {
//     return response?.data?.experiment;
//   } else {
//     throw new Error("did not return a experiment");
//   }
// }
export async function fetchExperiments(
  token: string | null,
): Promise<Experiment[]> {
  try {
    if (!token) {
      throw new Error("Auth token was not provided");
    }
    const response = await axios.get(`${backendURL}/experiment`, {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data?.experiments) {
      return response?.data?.experiments;
    } else {
      throw new Error("did not return a experiment list");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}
export async function fetchExperimentById(
  experimentId: string,
  token: string | null,
): Promise<Experiment> {
  try {
    // if (!token) {
    //   throw new Error("Auth token was not provided");
    // }
    const response = await axios.get(
      `${backendURL}/experiment/${experimentId}`,
      {
        headers: {
          "Content-Type": "application/json", // Example header
          Authorization: `Bearer ${token ?? ""}`,
        },
      },
    );
    if (response?.data?.experiment) {
      return response?.data?.experiment as Experiment;
    } else {
      throw new Error("did not return a experiment");
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      throw new Error(message);
    } else {
      throw new Error(error.message);
    }
  }
}

export async function updateExperiment(
  experimentId: string,
  updateData: { title?: string; stage?: string },
  token: string | null,
): Promise<Experiment> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.put(
    `${backendURL}/experiment/${experimentId}`,
    updateData,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response?.data?.experiment) {
    return response.data.experiment;
  } else {
    throw new Error("Failed to update experiment");
  }
}

export async function deleteExperiment(
  experimentId: string,
  token: string,
): Promise<void> {
  if (!token) {
    throw new Error("Auth token was not provided");
  }
  const response = await axios.delete(
    `${backendURL}/experiment/${experimentId}`,
    {
      headers: {
        "Content-Type": "application/json", // Example header
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (response.status !== 200) {
    throw new Error("Failed to delete experiment");
  }
}
