"use client";

import { ColumnDef } from "@tanstack/react-table";
import Decimal from "decimal.js";
import { ArrowUpDown } from "lucide-react";
import { Button } from "src/components/ui/button";
import { Characteristic } from "src/types/models/Characteristic";
import { ExperimentCharacteristicResult } from "src/types/models/ExperimentCharacteristicResult";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.

export const columns: ColumnDef<ExperimentCharacteristicResult>[] = [
  {
    id: "characteristic-info",
    accessorKey: "characteristic.name",

    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Characteristic
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: "llmModel.friendlyName",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Llm
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: "scoreDistanceFromLlmMedian",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Variation from Median
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    sortingFn: (rowA, rowB) => {
      // Ensure sorting uses the rounded numerical values
      const valueA = new Decimal(rowA.original.scoreDistanceFromLlmMedian || 0);
      const valueB = new Decimal(rowB.original.scoreDistanceFromLlmMedian || 0);

      return valueA.minus(valueB).toNumber();
    },
  },
];
